// Libs
import {HTTP_INTERCEPTORS, HttpBackend, HttpClient, HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

// Modules
import {OidcConfigModule} from 'src/app/shared/modules/oidc-config.module';
import {AppRoutingModule} from 'src/app/app-routing.module';
import {SharedModule} from 'src/app/shared/modules/shared.module';

// Services
import {InterceptorService} from 'src/app/shared/services/core/interceptor.service';

// Components
import {AppComponent} from 'src/app/app.component';
import {MainComponent} from './shared/components/main/main.component';
import {HeaderComponent} from './shared/components/header/header.component';
import { RedirectComponent } from './redirect/redirect.component';
import { ConfigService } from './shared/services/core/config.service';
import { environment } from 'src/environments/environment';
import { Config } from './shared/interfaces/config';
import { AuthModule, OpenIdConfiguration, StsConfigHttpLoader, StsConfigLoader } from 'angular-auth-oidc-client';
import { map } from 'rxjs';

// Layout


/**
 * Initialize the config of the app
 * @param config The configuration service
 */
export const httpLoaderFactory = (httpBackend: HttpBackend, configService: ConfigService) => {
  let httpClient = new HttpClient(httpBackend);
  const config = httpClient.get<Config>(`${environment.api}/config/`)
  .pipe(
    map((config: Config): OpenIdConfiguration => {
      configService.config = config;
      return {
        authority: `${config.keycloak.url}/realms/si-urgence`,
        redirectUrl: `${config.tucs.redirectUrl}`,
        postLogoutRedirectUri: `${config.tucs.postLogoutRedirectUri}`,
        clientId: 'si-urgence_client',
        scope: 'openid email offline_access',
        responseType: 'code',
        useRefreshToken: true,
        // No nonce is provided when a acces token is issued using a refresh token using keycloak as a provider
        // See issue: https://github.com/damienbod/angular-auth-oidc-client/issues/552
        ignoreNonceAfterRefresh: true,
        startCheckSession: true,
      };
    })
  );

  return new StsConfigHttpLoader(config);
};




@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    HeaderComponent,
    RedirectComponent,
  ],
  imports: [
    AuthModule.forRoot({
      loader: {
        provide: StsConfigLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpBackend, ConfigService]
      }
    }),
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    OidcConfigModule,
    SharedModule
  ],
  providers: [
    {
      // Add the http interceptor
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() { }
}
