// Libs
import { Injectable } from '@angular/core';
import { Ability, AbilityBuilder } from '@casl/ability';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { BehaviorSubject, Observable } from 'rxjs';
import { RoleService } from '../http/role.service';
import { UtilisateurService } from '../http/utilisateur.service';

// Interfaces
import { Utilisateur } from '../../interfaces/utilisateur';
import { tap } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class AccountService {
  user: Utilisateur;
  isLogged = new BehaviorSubject<boolean>(false)
  currentUser = new BehaviorSubject<Utilisateur>(null)

  constructor(
    public oidcSecurityService: OidcSecurityService,
    private utilisateurService: UtilisateurService,
    private roleService: RoleService,
    private ability: Ability,
  ) { }

  /**
   * Gets the user data from admin-api once the app is loading
   */
  async initializeUserData(): Promise<boolean> {
    const user = await this.utilisateurService.fetchMe().toPromise();
    this.user = user;
    this.isLogged.next(!!this.user);
    return !!this.user;
  }

  fetchMe(): Observable<Utilisateur> {
    return this.utilisateurService.fetchMe().pipe(
      tap(user => this.currentUser.next(user))
    )
  }

  async updateAbility(): Promise<void> {
    const { can, rules } = new AbilityBuilder(Ability);
    if (this.user) {
      const roles = await this.roleService.fetchRelatedRoles().toPromise();
      // super admin case
      if (roles.some(role => role.id === 1)) {
        can('manage', 'all')
      } else {
        await Promise.all(this.user.structures.map(async (structure) => {
          let role = roles.find(r => r.id === structure.StructureRoleUtilisateur.roleId)
          const filteredPermission = role.permissions.filter(p => p.moduleId === 5);
          await Promise.all(filteredPermission.map(async p => {
            can(p.action, 'Tucs');
          }))
        }))
      }
    }
    // remove duplicated rules
    let filteredRules = rules.filter((rule, index, self) =>
      self.findIndex(r => r.action === rule.action && r.subject === rule.subject && r.conditions === rule.conditions) === index)
    this.ability.update(filteredRules);
  }
}
