import { Injectable } from '@angular/core';
import { Config } from 'src/app/shared/interfaces/config';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  /**
   * Runtime configuration
   */
  public get config(): Config {
    return this.privateConfig;
  }

  /**
   * Runtime configuration
   */
  public set config(config: Config) {
    this.privateConfig = config;
  }

  /**
   * Has the config loaded
   */
  public get loaded(): boolean {
    return this.privateLoaded;
  }

  private privateConfig: Config;
  private privateLoaded: boolean;
  private currentModule: string;


  public set module(module: string){
    this.currentModule = module
  }

  public get module() :string {
    return this.currentModule;
  }

  constructor() {
    this.privateLoaded = true;
  }

}
