// Libs
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Services
import { EntityService } from './entity.service';
import { ConfigService } from 'src/app/shared/services/core/config.service';

// Interfaces
import { FonctionUtilisateur, PostUtilisateurPayload, Utilisateur, UtilisateurFilters } from '../../interfaces/utilisateur';
import { map, tap } from 'rxjs/operators';
import { Ability } from '@casl/ability';

@Injectable({
  providedIn: 'root'
})
export class UtilisateurService extends EntityService<Utilisateur> {

  constructor(
    configService: ConfigService,
    http: HttpClient,
    private ability: Ability,
  ) {
    super(configService, http, 'utilisateur');
  }

  /**
   * Fetch Self
   */
  public fetchMe(): Observable<Utilisateur> {
    return this.http.get<Utilisateur>(`${this.configService.config.admin.url}/utilisateur/me`)
    .pipe(
      map(utilisateur => {
        if (utilisateur.fonction?.toString() === 'Medecin'){
          utilisateur.fonction = FonctionUtilisateur.MEDECIN
        }
        return utilisateur;
      })
    );
  }

}
