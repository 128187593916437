// Services
import { ConfigService } from 'src/app/shared/services/core/config.service';

/**
 * Initialize factory dependencies
 */
export const initializeDeps = [
  ConfigService,
];

/**
 * Initialize the config of the app
 */
 export const initializeFactory = () => {
};
