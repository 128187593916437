// Libs
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RedirectComponent } from './redirect/redirect.component';
import { IsSignedInGuard } from './shared/guard/IsSignedInGuard';


const routes: Routes = [
   {
     path : '',
     component : RedirectComponent,
     canActivate: [IsSignedInGuard]
   },
  {
    path: 'tucs',
    loadChildren: () => import('./tucs/tucs.module').then(mod => mod.TucsModule),
    canActivate: [IsSignedInGuard]
  },
  {
    path: 'tucs-rgpd',
    loadChildren: () => import('./tucs-rgpd/tucs-rgpd.module').then(mod => mod.TucsRgpdModule),
    canActivate: [IsSignedInGuard]
  },
  {
    path: 'supervision-crra',
    loadChildren: () => import('./supervision-crra/supervision-crra.module').then(mod => mod.SupervisionCRRAModule),
    canActivate: [IsSignedInGuard]
  },
  {
    path: 'utilisateur',
    loadChildren: () => import('./utilisateur/utilisateur.module').then(mod => mod.UtilisateurModule),
    canActivate: [IsSignedInGuard]
  },
  {
    path: 'unauthorized',
    loadChildren: () => import('./unauthorized/unauthorized.module').then(mod => mod.UnauthorizedModule),
  },
  {
    path: 'lost',
    loadChildren: () => import('./lost/lost.module').then(mod => mod.LostModule),
  },
  {
    path: '**',
    redirectTo: 'lost',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
