// LIbs
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';
import { Module } from '../../interfaces/module';
import { Utilisateur } from '../../interfaces/utilisateur';
import { AccountService } from '../../services/auth/account.service';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { LayoutService } from '../../services/business/layout.service';
import { ConfigService } from '../../services/core/config.service';
import { ModuleService } from '../../services/http/module.service';
import { StorageService } from 'src/app/shared/services/business/storage.service';

// Interfaces

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  @Input() displayLogout: boolean;
  modules: Observable<Module[]>;
  user: Utilisateur;

  constructor(
    private moduleService: ModuleService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private accountService: AccountService,
    protected configService: ConfigService,
    private layoutService: LayoutService,
    private oidc: OidcSecurityService,
    private storageService: StorageService,

  ) {
      this.user = this.accountService.user;
  }

  ngOnInit() {
    this.modules = this.moduleService.fetchModulesNavigation();
  }

  navigateToModule(module: Module) {
    if(module.nom ==='Admin')
    {
      this.oidc.logoffLocal();

    }
    window.location.href = this.configService.config[module.nomConfig].baseUrl;
  }

    /**
   * Deconnects the user
   */
     logout(): void {
      this.authenticationService.logoffAndRevokeTokens();
      this.storageService.clearAll();
    }

    /**
     * Access to one's account
     */
    account(): void {
      this.layoutService.setIsSideMenuOpened(true);
      this.router.navigate([`/utilisateur/me`]);
    }
}
